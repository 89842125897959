header {
  background-color: #f0faec;
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  height: 7vh;
  /* border-bottom: 1px solid black; */
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  z-index: 10;
  position: relative;
}

.my-website {
  margin: 0.7rem 0.5rem 0.7rem auto;
  display: flex;
  align-items: center;
  background-color: rgba(48, 112, 196, 0.8);
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  gap: 0.5rem;
  color: white;
  font-size: 1rem;
}

.my-github {
  margin: 0.7rem 2rem 0.7rem 0.5rem;
  display: flex;
  align-items: center;
  background-color: #1d3557;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  gap: 0.5rem;
  color: white;
  font-size: 1rem;
}

.my-github-image {
  width: 1.5rem;
  height: auto;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
